import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {setTheme} from 'ngx-bootstrap/utils';
import {SpinnerService} from "./core/spinner.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  spinnerVisibility = false;
  private _subscriptionList: Subscription = new Subscription();

  constructor(
    private _spinnerService: SpinnerService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    setTheme('bs4');
  }

  ngOnInit(): void {
    this._subscriptionList.add(this._spinnerService.visibility.subscribe(state => {
      this.spinnerVisibility = state;
    }));

    this._subscriptionList.add(this._spinnerService.notVisibility.subscribe(state => {
      this.spinnerVisibility = state;
    }));
  }

  ngAfterViewChecked() {
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._subscriptionList.unsubscribe();
  }
}
