import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {SpinnerService} from './spinner.service';
import {ICustomResponseModel} from './toaster/toaster.model';
import {ToasterService} from './toaster/toaster.service';
import {AuthService} from "./services/auth.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private _spinner: SpinnerService,
    private _authService: AuthService,
    private _toasterService: ToasterService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* Added delay (800 milliseconds) to open spinner, to prevent blinking screen when response comes very fast
       If response comes within in 800 milliseconds then spinner will not show
    */
    const timer = setTimeout(() => this._spinner.visibility.emit(true), 800);

    const accessToken = this._authService.getAccessToken();
    if (accessToken) {
      const headers = request.headers.set('Authorization', `Bearer ${accessToken}`);
      request = request.clone({headers});
    }
    return next.handle(request).pipe(
      finalize(() => {
        clearTimeout(timer);
        this._spinner.notVisibility.emit(false);
      }),
      tap(
        event => {
        }, (error) => {
          if (error instanceof HttpErrorResponse) {
            this.transformBlobToJson(error).then((res: any) => {
              this.handleError(error, res);
            });
          }
        })
    );
  }

  /**
   * It id for handle http request error and return an observable
   * @param error
   * @returns {ErrorObservable}
   */
  private handleError = (response: HttpResponseBase, text: string): Observable<any> => {
    const errorResponse: ICustomResponseModel = {
      statusCode: response.status,
      message: text
    };
    this._toasterService.errorMethod(errorResponse);
    return throwError(response);
  };

  private transformBlobToJson = (response: HttpResponseBase): Promise<string> => {
    return new Promise(resolve => {
      const responseBlob = response instanceof HttpResponse
        ? response.body : (<any>response).error instanceof Blob
          ? (<any>response).error : undefined;
      blobToText(responseBlob).pipe(map(responseText => {
        if (responseText !== null) {
          const responseObject: ICustomResponseModel = JSON.parse(responseText);
          return responseObject.message ? responseObject.message : responseObject.title;
        }
        return null;
      })).subscribe(res => {
        return resolve(res);
      });
    });
  };
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}



