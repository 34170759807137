import {Injectable} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import {MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef} from "@angular/material/legacy-snack-bar";
import {ICustomResponseModel, ISnackBarDataModel} from "./toaster.model";
import {ToasterComponent} from "./toaster.component";
import {HttpErrorStatusCode, HttpSuccessStatusCode} from "../../enums/http-request-status-code.enum";

@Injectable()
export class ToasterService {

  private _snackBarRef: MatSnackBarRef<ToasterComponent>;
  snackBarConfig: MatSnackBarConfig;

  constructor(private _snackBar: MatSnackBar) {
    // basic configuration for snackbar
    this.snackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 4000
    };
  }

  /**
   * To show toaster/ snackbar on scree when http request has succeeded
   * @param response: take http success response as 'ICustomResponseModel'
   */
  successMethod(response: ICustomResponseModel) {
    // add default color for snackbar
    this.snackBarConfig.panelClass = ['default-snackbar'];

    // It is default data which will get show on screen with snackbar
    this.snackBarConfig.data = <ISnackBarDataModel>{
      icon: 'fa-check',
      message: response.message
    };

    // To decide snackbar type as per http request response status code
    switch (response.statusCode) {
      case HttpSuccessStatusCode.OK: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.ACCEPTED: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.CREATED: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
      case HttpSuccessStatusCode.NO_CONTENT: {
        this.snackBarConfig.panelClass.push('success-snackbar');
        break;
      }
    }
    this.showToaster();
  }

  /**
   * To show toaster/ snackbar on scree when http request has failed
   * @param response: take http error response as 'ICustomResponseModel'
   */
  errorMethod(response: ICustomResponseModel) {
    // add default color for snackbar
    this.snackBarConfig.panelClass = ['default-snackbar'];

    // It is default data which will get show on screen with snackbar
    this.snackBarConfig.data = <ISnackBarDataModel>{
      icon: 'fa-exclamation-triangle',
      message: response.message
    };

    // To decide snackbar type as per http request response status code
    switch (response.statusCode) {
      case HttpErrorStatusCode.NOT_FOUND: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.BAD_REQUEST: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.FORBIDDEN: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.METHOD_NOT_ALLOWED: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.NOT_ACCEPTABLE: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.REQUEST_TIMEOUT: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.UNAUTHORIZED: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.UNSUPPORTED_MEDIA_TYPE: {
        this.snackBarConfig.panelClass.push('warning-snackbar');
        break;
      }
      case HttpErrorStatusCode.INTERNAL_SERVER_ERROR: {
        this.snackBarConfig.data.icon = 'fa-times';
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
      case HttpErrorStatusCode.SERVICE_UNAVAILABLE: {
        this.snackBarConfig.data.icon = 'fa-times';
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
      default: {
        this.snackBarConfig.data = <ISnackBarDataModel>{
          icon: 'fa-info',
          message: 'Server is not running ! Please try after some time'
        };
        this.snackBarConfig.panelClass.push('error-snackbar');
        break;
      }
    }
    this.showToaster();
  }

  /**
   * To show toaster/ snackbar on screen
   */
  private showToaster() {
    this._snackBarRef = this._snackBar.openFromComponent(ToasterComponent, this.snackBarConfig);
  }
}





