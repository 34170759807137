import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_ID} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PageNotFoundComponent} from './not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToasterModule} from "./core/toaster/toaster.module";
import {ToasterService} from "./core/toaster/toaster.service";
import {AmpV1_BASE_URL, AmpV1Service,} from './core/services/AmpV1Service.Generated';
import {environment} from '../environments/environment';
import {HttpInterceptorService} from "./core/http-interceptor.service";
import {SpinnerService} from "./core/spinner.service";
import {AuthService} from "./core/services/auth.service";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ToasterModule
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  providers: [
    AuthService,
    ToasterService,
    AmpV1Service,
    SpinnerService,
    {
      provide: AmpV1_BASE_URL,
      useValue: environment.AmpV1BaseUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: APP_ID,
      useValue: 'ng-cli-universal' 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
