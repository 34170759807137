import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './not-found.component';
import {AuthGuard} from "./core/auth-guard";

const routes: Routes = [
  {
    path: 'preview/:id',
    loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule),
    canLoad: [AuthGuard],
    data: { title: 'Preview' }
  },
  {
    path: '',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    canLoad: [AuthGuard],
    data: { title: 'Manager' }
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

const extraOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload', // or 'ignore'
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
