import {Component, Inject} from "@angular/core";
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from "@angular/material/legacy-snack-bar";
import {ISnackBarDataModel} from "./toaster.model";

@Component({
  selector: 'app-snack-bar',
  template: `<i class="fas fa-lg fa-fw" [ngClass]="data.icon"></i> {{ data.message }}
  <button type="button" class="close btn-outline-none pl-3" (click)="dismissSnackbar()">
    <span aria-hidden="true">&times;</span>
  </button>`,
})
export class ToasterComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarDataModel,
    private _snackRef: MatSnackBarRef<ToasterComponent>,
  ) {
  }

  dismissSnackbar() {
    this._snackRef.dismiss();
  }
}
