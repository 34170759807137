import {NgModule} from "@angular/core";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import {ToasterComponent} from "./toaster.component";
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [ToasterComponent],
    imports: [MatSnackBarModule, CommonModule],
    exports: [ToasterComponent]
})
export class ToasterModule {
}
