import {Component} from '@angular/core';

@Component({
    template: `
        <section class="page-center">
            <div class="row text-center">
                <div class="col-md-12">
                    <figure>
                        <img src="assets/img/error404.svg" alt="error image"/>
                    </figure>
                </div>
                <div class="col-md-12">
                    <h1>Oops!!</h1>
                    <h3>This is not the page your're looking for !</h3>
                </div>
            </div>
        </section>`
})
export class PageNotFoundComponent {
}
