import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, Router } from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private _authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    if (!this.hasTitleProperty(route)) {
      // Handle the case where ActivatedRouteSnapshot does not have a title property
      console.error('Route does not have a title property:', route);
      this.router.navigate(['/']); // Redirect to home or any other default route
      return false;
    }

    return new Promise(resolve => {
      setTimeout(() => {
        if (this._authService.isLoggedIn()) {
          return resolve(true);
        } else {
          this._authService.login();
          return resolve(false);
        }
      }, 0);
    });
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> | boolean {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this._authService.isLoggedIn()) {
          return resolve(true);
        } else {
          this._authService.login();
          return resolve(false);
        }
      }, 0);
    });
  }

  /**
   * has Title Property
   * @param route
   * @returns
   */
  private hasTitleProperty(route: ActivatedRouteSnapshot): boolean {
    return !!route.data && !!route.data.title;
  }

}
